import { createI18n, I18n } from "vue-i18n";
import deDE from "./i18n/de_DE.json";

type MessageSchema = typeof deDE;

const i18n: I18n = createI18n<[MessageSchema], "de-DE">({
  locale: "de-DE",
  fallbackLocale: "en",
  legacy: false,
  messages: {
    "de-DE": deDE,
  },
});

export default i18n;
