
import { defineComponent } from "vue";
import classNameMixin from "../Mixins/classNameMixin";

export default defineComponent({
  name: "BaseGridContainer",

  mixins: [classNameMixin],

  props: {
    isWidescreen: Boolean,
    isFullHD: Boolean,
    isMaxDesktop: Boolean,
    isMaxWidescreen: Boolean,
  },

  computed: {
    classes(): string {
      const classes: (string | boolean)[] = [
        "container",
        this.isWidescreen && "is-widescreen",
        this.isFullHD && "is-full-hd",
        this.isMaxDesktop && "is-max-desktop",
        this.isMaxWidescreen && "is-max-widescreen",
      ];

      return this.formatClassNames(classes);
    },
  },
});
