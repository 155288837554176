
import { defineComponent } from "vue";
import classNameMixin from "../Mixins/classNameMixin";

export default defineComponent({
  name: "BaseButton",

  mixins: [classNameMixin],

  props: {
    isSmall: Boolean,
    isNormal: Boolean,
    isMedium: Boolean,
    isLarge: Boolean,
    isFullWidth: Boolean,
    isOutlined: Boolean,
    isRounded: Boolean,
    isLoading: Boolean,
    disabled: Boolean,
    iconName: String,
    iconRight: Boolean,
    fab: Boolean,
    iconOnly: Boolean,
  },

  computed: {
    classes(): string {
      const classes: (string | boolean)[] = [
        "button",
        this.disabled && "is-clickable",
        this.isSmall && "is-small",
        this.isNormal && "is-normal",
        this.isMedium && "is-medium",
        this.isLarge && "is-large",
        this.isFullWidth && "is-fullwidth",
        this.isOutlined && "is-outlined",
        this.isRounded && "is-rounded",
        this.isLoading && "is-loading",
      ];

      return this.formatClassNames(classes);
    },
  },
});
