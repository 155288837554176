import api from "../base";
import { Routes } from "./routes";
import { AxiosResponse } from "axios";

/**
 * Retrieve navigation data from api
 */
export const getMenus = (): Promise<AxiosResponse<unknown>> => api.get(Routes.MENUS_ALL);

/**
 * Retrieve menu items
 */
export const getMenu = (id: number): Promise<AxiosResponse<object>> =>
  api.get(`${Routes.MENUS_SINGLE}/${id}`);

/**
 * Retrieve a list of all pages
 */
export const getPages = (): Promise<AxiosResponse<unknown>> =>
  api.get(`${Routes.PAGES_ALL}?per_page=100`);

/**
 * Retrieve a single post
 */
export const getPost = (slug: string): Promise<AxiosResponse<object[]>> =>
  api.get(`${Routes.POSTS_SINGLE}/?slug=${slug}`);

/**
 * Retrieve a single media item
 */
export const getMediaItem = (id: number): Promise<AxiosResponse<unknown>> =>
  api.get(`${Routes.MEDIA_SINGLE}/${id}`);

/**
 * Retrieve a single page
 */
export const getPage = (slug: string): Promise<AxiosResponse<object[]>> =>
  api.get(`${Routes.PAGES_SINGLE}/?slug=${slug}`);
