import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer has-background-light" }
const _hoisted_2 = { class: "content has-text-centered-mobile" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "has-text-right-tablet has-text-centered-mobile content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseGridCell = _resolveComponent("BaseGridCell")!
  const _component_FooterMenuItem = _resolveComponent("FooterMenuItem")!
  const _component_BaseGridRow = _resolveComponent("BaseGridRow")!
  const _component_BaseGridContainer = _resolveComponent("BaseGridContainer")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_BaseGridContainer, null, {
      default: _withCtx(() => [
        _createVNode(_component_BaseGridRow, { vCentered: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseGridCell, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", {
                    innerHTML: _ctx.t('FOOTER.COPYRIGHT', { year: _ctx.thisYear })
                  }, null, 8, _hoisted_3)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_BaseGridCell, {
              width: "2",
              class: "has-text-centered"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.lilie,
                  width: "24",
                  class: "has-text-grey-light",
                  alt: "logo"
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseGridCell, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
                    return (_openBlock(), _createBlock(_component_FooterMenuItem, {
                      key: index,
                      menuItem: _ctx.withType(item)
                    }, null, 8, ["menuItem"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}