import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "placeholder", "disabled", "readonly", "id", "required"]
const _hoisted_4 = {
  key: 0,
  class: "icon is-left"
}
const _hoisted_5 = {
  key: 1,
  class: "icon is-right"
}
const _hoisted_6 = {
  key: 2,
  class: "icon is-right"
}
const _hoisted_7 = {
  key: 3,
  class: "icon is-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label && _ctx.type !== 'hidden')
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.fieldId,
          class: "label"
        }, _toDisplayString(`${_ctx.label}${_ctx.required ? " *" : ""}`), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass(`control${_ctx.iconLeft ? ' has-icons-left' : ''}${
        _ctx.iconRight || _ctx.isError || _ctx.isSuccess ? ' has-icons-right' : ''
      }`)
    }, [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(`${_ctx.classes}${' ' + _ctx.statusClasses}`),
        type: _ctx.type,
        placeholder: `${_ctx.placeholder}${_ctx.required ? ' *' : ''}`,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        id: _ctx.fieldId,
        required: _ctx.required,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
      }, null, 10, _hoisted_3), [
        [_vModelDynamic, _ctx.inputValue]
      ]),
      (_ctx.iconLeft)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_BaseIcon, { name: _ctx.iconLeft }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.iconRight && !_ctx.isError && !_ctx.isSuccess)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createVNode(_component_BaseIcon, { name: _ctx.iconRight }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isSuccess)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            _createVNode(_component_BaseIcon, { name: "check" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.isError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createVNode(_component_BaseIcon, { name: "exclamation-triangle" })
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.isSuccess || _ctx.isError)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(`help ${_ctx.statusClasses}`)
        }, _toDisplayString(_ctx.successMessage || _ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ]))
}