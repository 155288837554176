import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-image" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", {
        class: _normalizeClass(_ctx.classes)
      }, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("img", {
              src: _ctx.src,
              onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initImage && _ctx.initImage(...args)))
            }, null, 40, _hoisted_3), [
              [_vShow, _ctx.isLoaded]
            ])
          ]),
          _: 1
        })
      ], 2)
    ])
  ]))
}