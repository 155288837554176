import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const PageView = () => import("@/views/Page.vue");
const HomeView = () => import("@/views/Home.vue");
const PostView = () => import("@/views/Post.vue");
const EventsView = () => import("@/views/Events.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/page/:slug",
    name: "page",
    component: PageView,
  },
  {
    path: "/post/:slug",
    name: "post",
    component: PostView,
  },
  {
    path: "/events",
    name: "events",
    component: EventsView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: { name: "page", params: { slug: "not-found" } },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
