
import { random } from "lodash";
import { defineComponent } from "vue";
import classNameMixin from "../Mixins/classNameMixin";

export default defineComponent({
  name: "BaseInput",

  mixins: [classNameMixin],

  props: {
    modelValue: String,
    label: String,

    type: {
      type: String,
      default: "text",
    },

    placeholder: String,
    isSmall: Boolean,
    isNormal: Boolean,
    isMedium: Boolean,
    isLarge: Boolean,
    isRounded: Boolean,
    isLoading: Boolean,
    isHovered: Boolean,
    isFocused: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    isStatic: Boolean,
    iconLeft: String,
    iconRight: String,
    isSuccess: Boolean,
    isError: Boolean,
    successMessage: String,
    errorMessage: String,
    required: Boolean,
  },

  data() {
    return {
      randomId: 0,
      formValue: "",
    };
  },

  computed: {
    classes(): string {
      const classes: (string | boolean)[] = [
        "input",
        this.fieldId,
        this.isSmall && "is-small",
        this.isNormal && "is-normal",
        this.isMedium && "is-medium",
        this.isLarge && "is-large",
        this.isRounded && "is-rounded",
        this.isLoading && "is-loading",
        this.isHovered && "is-hovered",
        this.isFocused && "is-focused",
        this.isStatic && "is-static",
      ];

      return this.formatClassNames(classes);
    },

    statusClasses(): string {
      const classes: (string | boolean)[] = [
        this.isSuccess && "is-success",
        this.isError && "is-danger",
      ];

      return this.formatClassNames(classes);
    },

    fieldId(): string {
      return `input-${this.randomId}`;
    },

    inputValue: {
      get() {
        return this.modelValue;
      },

      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  created() {
    this.randomId = random(10000);
  },
});
