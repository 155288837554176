import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["placeholder", "disabled", "readonly", "rows", "id", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.fieldId,
          class: "label"
        }, _toDisplayString(`${_ctx.label}${_ctx.required ? " *" : ""}`), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass(`control${_ctx.isLoading ? ' ' + _ctx.loadingClasses : ''}`)
    }, [
      _withDirectives(_createElementVNode("textarea", {
        class: _normalizeClass(`${_ctx.classes}${' ' + _ctx.statusClasses}`),
        placeholder: `${_ctx.placeholder}${_ctx.required ? ' *' : ''}`,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        rows: _ctx.rows,
        id: _ctx.fieldId,
        required: _ctx.required,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textareaValue) = $event))
      }, null, 10, _hoisted_3), [
        [_vModelText, _ctx.textareaValue]
      ])
    ], 2),
    (_ctx.isSuccess || _ctx.isError)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(`help ${_ctx.statusClasses}`)
        }, _toDisplayString(_ctx.successMessage || _ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ]))
}