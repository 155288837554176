
import lilie from "@/assets/img/lilie.svg";
import { useI18n } from "vue-i18n";
import useWordpressLink from "@/use/useWordpressLink";
import useDate from "@/use/useDate";
import FooterMenuItem from "./partials/FooterMenuItem.vue";
import { useMenuStore } from "@/store/menus";
import { computed, defineComponent } from "vue";
import { MenuItem } from "./types";

export default defineComponent({
  name: "SiteFooter",

  components: {
    FooterMenuItem,
  },

  setup() {
    const { t } = useI18n();
    const { goToPage } = useWordpressLink();
    const { year } = useDate();

    const menuStore = useMenuStore();
    const thisYear = year(new Date());

    const menuItems = computed<object[]>(() => menuStore.getFooterNavigation.items);

    const withType = (data: unknown): MenuItem => {
      return data as MenuItem;
    };

    return { t, goToPage, thisYear, menuItems, withType, lilie };
  },
});
