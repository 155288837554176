import { defineStore, StoreDefinition } from "pinia";
import { MenusState } from "./types";
import { getMenu } from "@/api/modules/wordpress";

export const useMenuStore: StoreDefinition = defineStore("menus", {
  state: (): MenusState => ({
    mainNavigation: {},
    footerNavigation: {},
    isLoading: false,
  }),

  actions: {
    async getNavigationItems(id: number) {
      const data = await getMenu(id);

      return data;
    },

    async getMainNavigationData() {
      const { data } = await this.getNavigationItems(7);
      this.mainNavigation = data;
    },

    async getFooterNavigationData() {
      const { data } = await this.getNavigationItems(46);
      this.footerNavigation = data;
    },

    async initializeAllMenus() {
      this.isLoading = true;
      try {
        await this.getMainNavigationData();
        await this.getFooterNavigationData();
      } finally {
        this.isLoading = false;
      }
    },
  },

  getters: {
    getFooterNavigation(state: MenusState) {
      return state.footerNavigation;
    },

    getMainNavigation(state: MenusState) {
      return state.mainNavigation;
    },
  },
});
