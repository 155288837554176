import regex from "@/data/regex";
import { Router, useRouter } from "vue-router";

interface UseWordPressLink {
  goToPage: (slug: string | null, type: string, url?: string) => Promise<void>;
}

const useWordpressLink = (): UseWordPressLink => {
  const router: Router = useRouter();

  const goToPage = async (slug: string | null, type: string, url?: string): Promise<void> => {
    if (type === "custom" && url?.match(regex.routerLink)) {
      try {
        await router.push({ name: url.split(".")[1] });
      } catch {
        return;
      }
    } else if (type === "custom" && url) {
      window.open(url, "_blank");
    }

    if (type === "custom") return;

    try {
      await router.push({ name: type, params: slug ? { slug } : undefined });
    } catch {
      return;
    }
  };

  return { goToPage };
};

export default useWordpressLink;
