
import Navigation from "@/components/Navigation/Navigation.vue";
import Footer from "@/components/Footer/Footer.vue";
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMenuStore } from "./store/menus";
import { useOptionsStore } from "./store/options";

export default defineComponent({
  name: "DPSGOS",

  setup() {
    const { t } = useI18n();
    const isLoading = ref(true);
    const setTitle = (): void => {
      document.title = t("APP_TITLE");
    };

    const menuStore = useMenuStore();
    const optionsStore = useOptionsStore();

    onMounted(async () => {
      isLoading.value = true;
      try {
        await menuStore.initializeAllMenus();
        await optionsStore.getOptions();
        setTitle();
      } finally {
        isLoading.value = false;
      }
    });

    onUpdated(() => {
      setTitle();
    });

    return { t, isLoading };
  },

  components: {
    Navigation,
    Footer,
  },
});
