
import useWordpressLink from "@/use/useWordpressLink";
import { defineComponent, PropType } from "vue";
import { MenuItem } from "../types";

export default defineComponent({
  name: "FooterMenuItem",

  setup() {
    const { goToPage } = useWordpressLink();

    return { goToPage };
  },

  props: {
    menuItem: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
  },
});
