import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "icon"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: _ctx.classes,
    disabled: _ctx.disabled
  }, _ctx.$attrs), [
    (_ctx.iconName && !_ctx.iconRight)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_BaseIcon, { name: _ctx.iconName }, null, 8, ["name"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.iconOnly)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    (_ctx.iconName && _ctx.iconRight)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createVNode(_component_BaseIcon, { name: _ctx.iconName }, null, 8, ["name"])
        ]))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}