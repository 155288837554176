
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseTitle",

  props: {
    size: {
      type: String,
      default: "1",
    },

    subtitle: Boolean,
  },

  computed: {
    titleSize(): number {
      return parseInt(this.size, 10);
    },

    classes(): string {
      return `${!this.subtitle ? "title" : "subtitle"} is-${this.size}`;
    },
  },
});
