
import { GridDefinitionAdvanced, GridDefinitionSimple } from "@/types/GridDefinitions";
import { computed } from "@vue/reactivity";
import { isArray, isEmpty } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseGridCell",

  props: {
    width: {
      type: undefined,
      default: null,
    },

    offset: {
      type: undefined,
      default: null,
    },
  },

  setup(props) {
    const renderSimpleClass = (prop: GridDefinitionSimple, classPrefix: string): string =>
      `${classPrefix}-${prop}`;

    const renderAdvancedSingleClass = (prop: GridDefinitionAdvanced, classPrefix: string): string =>
      `${classPrefix}-${prop[0]}-${prop[1]}`;

    const renderAdvancedMultipleClass = (prop: GridDefinitionAdvanced[], classPrefix: string) =>
      prop.map((item: GridDefinitionAdvanced) => `${classPrefix}-${item[0]}-${item[1]}`).join(" ");

    const renderClasses = (prop: unknown, classPrefix = "is") => {
      if (isEmpty(prop)) return undefined;

      if (typeof prop === "string" || typeof prop === "number") {
        return renderSimpleClass(prop as GridDefinitionSimple, classPrefix);
      }

      if (isArray(prop) && typeof prop[0] === "number") {
        return renderAdvancedSingleClass(prop as GridDefinitionAdvanced, classPrefix);
      }

      if (isArray(prop) && isArray(prop[0])) {
        return renderAdvancedMultipleClass(prop as GridDefinitionAdvanced[], classPrefix);
      }
    };

    const classes = computed<string>(() =>
      [renderClasses(props.width), renderClasses(props.offset, "is-offset")]
        .filter((item) => !isEmpty(item))
        .join(" ")
    );

    return { classes };
  },
});
