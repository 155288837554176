import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.titleSize === 1)
      ? (_openBlock(), _createElementBlock("h1", {
          key: 0,
          class: _normalizeClass(_ctx.classes)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.titleSize === 2)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 1,
          class: _normalizeClass(_ctx.classes)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.titleSize === 3)
      ? (_openBlock(), _createElementBlock("h3", {
          key: 2,
          class: _normalizeClass(_ctx.classes)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.titleSize === 4)
      ? (_openBlock(), _createElementBlock("h4", {
          key: 3,
          class: _normalizeClass(_ctx.classes)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.titleSize === 5)
      ? (_openBlock(), _createElementBlock("h5", {
          key: 4,
          class: _normalizeClass(_ctx.classes)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.titleSize === 6)
      ? (_openBlock(), _createElementBlock("h6", {
          key: 5,
          class: _normalizeClass(_ctx.classes)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true)
  ]))
}