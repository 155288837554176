import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bae9ca6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "appContainer" }
const _hoisted_2 = {
  key: 1,
  ref: "appBody",
  class: "appBody"
}
const _hoisted_3 = {
  key: 2,
  ref: "appFooter",
  class: "appFooter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_BaseProgressBar = _resolveComponent("BaseProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Navigation, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ], 512))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Footer)
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_BaseProgressBar, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}