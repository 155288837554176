
import { defineComponent } from "vue";
import classNameMixin from "../Mixins/classNameMixin";

export default defineComponent({
  name: "BaseNotification",

  mixins: [classNameMixin],

  props: {
    isInfo: Boolean,
    isSuccess: Boolean,
    isWarning: Boolean,
    isError: Boolean,
    isPrimary: Boolean,
  },

  data() {
    return {
      show: true,
    };
  },

  computed: {
    classes(): string {
      const classes: (string | boolean)[] = [
        "notification",
        this.isInfo && "is-info",
        this.isSuccess && "is-success",
        this.isWarning && "is-warning",
        this.isError && "is-danger",
        this.isPrimary && "is-primary",
      ];

      return this.formatClassNames(classes);
    },
  },
});
