import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, Pinia } from "pinia";
import "@/assets/styles/main.scss";
import "@/assets/styles/block-library.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import GridContainer from "@/components/Base/Grid/GridContainer.vue";
import GridRow from "@/components/Base/Grid/GridRow.vue";
import GridCell from "@/components/Base/Grid/GridCell.vue";
import Title from "@/components/Base/Title/Title.vue";
import Button from "@/components/Base/Button/Button.vue";
import Icon from "@/components/Base/Icon/Icon.vue";
import ProgressBar from "@/components/Base/ProgressBar/ProgressBar.vue";
import Input from "@/components/Base/Form/Input.vue";
import TextArea from "@/components/Base/Form/TextArea.vue";
import Notification from "@/components/Base/Notification/Notification.vue";
import RenderImage from "@/components/Base/RenderImage/RenderImage.vue";
import i18n from "./i18n";
import "swiper/scss";
import "swiper/scss/navigation";

const pinia: Pinia = createPinia();

const app = createApp(App);

app.component("BaseGridContainer", GridContainer);
app.component("BaseGridRow", GridRow);
app.component("BaseGridCell", GridCell);
app.component("BaseTitle", Title);
app.component("BaseButton", Button);
app.component("BaseIcon", Icon);
app.component("BaseProgressBar", ProgressBar);
app.component("BaseInput", Input);
app.component("BaseTextArea", TextArea);
app.component("BaseNotification", Notification);
app.component("BaseRenderImage", RenderImage);

app.use(router).use(pinia).use(i18n).mount("#app");
