
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseIcon",

  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },

    size: {
      type: String,
      required: false,
      default: "",
    },
  },

  computed: {
    classes(): string {
      const classes: string[] = [];

      classes.push(this.name);
      classes.push(this.size);

      return classes.join(" ");
    },
  },
});
