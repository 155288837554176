import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.classes)
          }, [
            _createElementVNode("button", {
              class: "delete",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
            }),
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}