
import { GridDefinitionSimple, GridDefinitionAdvanced } from "@/types/GridDefinitions";
import { defineComponent, computed } from "vue";

import { isArray, isBoolean, isEmpty, isObject } from "lodash";

export default defineComponent({
  name: "GridRow",

  setup(props) {
    const variableClass = "is-variable";

    const renderSimpleClass = (prop: GridDefinitionSimple, classPrefix: string): string =>
      `${variableClass} ${classPrefix}-${prop}`;

    const renderAdvancedSingleClass = (prop: GridDefinitionAdvanced, classPrefix: string): string =>
      `${classPrefix}-${prop[0]}-${prop[1]}`;

    const renderClasses = (prop: unknown, classPrefix = "is"): undefined | string => {
      if (isEmpty(prop)) return undefined;

      if (typeof prop === "string" || typeof prop === "number") {
        return renderSimpleClass(prop as GridDefinitionSimple, classPrefix);
      }

      if (isObject(prop) && !isArray(prop)) {
        return renderAdvancedSingleClass(prop as GridDefinitionAdvanced, classPrefix);
      }
    };

    const propClasses = () => {
      return [
        props.isMobile && "is-mobile",
        props.isDesktop && "is-desktop",
        props.isGapless && "is-gapless",
        props.isMultiline && "is-multiline",
        props.vCentered && "is-vcentered",
        props.isCentered && "is-centered",
      ];
    };

    const classes = computed<undefined | string>(() =>
      [renderClasses(props.gap), ...propClasses()].filter((item) => !isBoolean(item)).join(" ")
    );

    return { classes };
  },

  props: {
    isMobile: Boolean,
    isDesktop: Boolean,
    isGapless: Boolean,
    isMultiline: Boolean,
    gap: {
      type: undefined,
      default: null,
    },
    vCentered: Boolean,
    isCentered: Boolean,
    isVariable: Boolean,
  },
});
