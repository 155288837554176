import { defineStore, StoreDefinition } from "pinia";
import { OptionsState } from "./types";
import { getOptions } from "@/api/modules/acf";

export const useOptionsStore: StoreDefinition = defineStore("options", {
  state: (): OptionsState => ({
    data: {},
    isLoading: false,
  }),

  actions: {
    async getOptions() {
      this.isLoading = true;

      try {
        const { data } = await getOptions();
        this.data = data;
      } finally {
        this.isLoading = false;
      }
    },
  },

  getters: {
    getOptionsData: (state: OptionsState) => state.data,
  },
});
