import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToPage(_ctx.menuItem.object_slug, _ctx.menuItem.object, _ctx.menuItem.url))),
    title: _ctx.menuItem.title,
    class: "mr-3"
  }, _toDisplayString(_ctx.menuItem.title), 9, _hoisted_1))
}