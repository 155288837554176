
import logo from "@/assets/img/logo.svg";
import { defineComponent, onMounted, Ref, ref } from "vue";
import useWordpressLink from "@/use/useWordpressLink";
import { useI18n } from "vue-i18n";
import { useMenuStore } from "@/store/menus";
import { MenuItem } from "../Footer/types";

type IsActiveSub = {
  [index: string]: boolean;
};

export default defineComponent({
  name: "MainNavigation",

  setup() {
    const { goToPage } = useWordpressLink();
    const { t } = useI18n();
    const menuStore = useMenuStore();

    const isActive: Ref<boolean> = ref(false);
    const isActiveSub: Ref<IsActiveSub> = ref({});

    const navItemClasses = (children: object[]): string => {
      const classes = [];
      classes.push("navbar-item");
      if (children) classes.push("has-dropdown");

      return classes.join(" ");
    };

    const toggleMenu = () => {
      isActive.value = !isActive.value;
    };

    const closeDropDown = () => {
      Object.keys(isActiveSub.value).forEach((item) => {
        isActiveSub.value[item] = false;
      });
    };

    const toggleDropDown = (id: number): void => {
      const currentToggle = isActiveSub.value[id];
      closeDropDown();
      isActiveSub.value[id] = !currentToggle;
    };

    const goHome = (): void => {
      closeDropDown();
      goToPage(null, "Home");
    };

    const setIds = (): { [index: number]: boolean } => {
      const ids: { [index: number]: boolean } = {};

      menuStore.getMainNavigation.items.forEach((item: MenuItem) => {
        if (item.children) {
          ids[item.id] = false;
        }
      });

      return ids;
    };

    const openPage = (slug: string | null, type: string, url: string) => {
      closeDropDown();
      toggleMenu();
      goToPage(slug, type, url);
    };

    onMounted(() => {
      isActiveSub.value = setIds();

      window.onclick = (event) => {
        const className = (event.target as HTMLElement).className;

        if (className !== "navbar-link") {
          closeDropDown();
        }
      };
    });

    return {
      goToPage,
      t,
      menuItems: menuStore.getMainNavigation.items,
      logo,
      navItemClasses,
      toggleMenu,
      toggleDropDown,
      goHome,
      setIds,
      openPage,
      isActive,
      isActiveSub,
    };
  },
});
