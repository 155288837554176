import dayjs from "dayjs";

interface UseDate {
  date: (value: Date) => string;
  dateShort: (value: Date) => string;
  dateShorter: (value: Date) => string;
  dateTime: (value: Date) => string;
  time: (value: Date) => string;
  year: (value: Date) => string;
  customDate: (value: Date, format: string) => string;
  dateIsInPast: (value: Date) => boolean;
  dateIsInFuture: (value: Date) => boolean;
}

export const useDate = (): UseDate => {
  const date = (value: Date): string => {
    if (!value) return "";

    return dayjs(value).format("DD.MM.YYYY");
  };

  const dateShort = (value: Date): string => {
    if (!value) return "";

    return dayjs(value).format("DD.MM.YY");
  };

  const dateShorter = (value: Date): string => {
    if (!value) return "";

    return dayjs(value).format("DD.MM.");
  };

  const time = (value: Date): string => {
    if (!value) return "";

    return dayjs(value).format("HH:mm");
  };

  const dateTime = (value: Date): string => {
    if (!value) return "";

    return dayjs(value).format("DD.MM.YYYY, HH:mm");
  };

  const year = (value: Date): string => {
    if (!value) return "";

    return dayjs(value).format("YYYY");
  };

  const customDate = (value: Date, format: string): string => {
    if (!value) return "";

    return dayjs(value).format(format);
  };

  const dateIsInPast = (value: Date): boolean => {
    return dayjs().isAfter(dayjs(value));
  };

  const dateIsInFuture = (value: Date): boolean => {
    return dayjs().isBefore(dayjs(value));
  };

  return {
    date,
    dateShort,
    dateShorter,
    dateTime,
    time,
    year,
    customDate,
    dateIsInPast,
    dateIsInFuture,
  };
};

export default useDate;
