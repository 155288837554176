
import { defineComponent } from "vue";
import classNameMixin from "../Mixins/classNameMixin";

export default defineComponent({
  name: "BaseRender",

  mixins: [classNameMixin],

  props: {
    src: String,
    is16: Boolean,
    is24: Boolean,
    is32: Boolean,
    is48: Boolean,
    is64: Boolean,
    is96: Boolean,
    is128: Boolean,
    isSquare: Boolean,
    is5by4: Boolean,
    is4by3: Boolean,
    is3by2: Boolean,
    is16by9: Boolean,
    is2by1: Boolean,
    is3by1: Boolean,
    is4by5: Boolean,
    is3by4: Boolean,
    is2by3: Boolean,
    is3by5: Boolean,
    is9by16: Boolean,
    is1by2: Boolean,
    is1by3: Boolean,
    isRounded: Boolean,
  },

  data() {
    return {
      isLoaded: false,
    };
  },

  computed: {
    classes(): string {
      const classes: (string | boolean)[] = [
        "image",
        "animatedBackground",
        this.is16 && "is-16x16",
        this.is24 && "is-24x24",
        this.is32 && "is-32x32",
        this.is48 && "is-48x48",
        this.is64 && "is-64x64",
        this.is96 && "is-96x96",
        this.is128 && "is-128x128",
        this.isSquare && "is-square",
        this.is5by4 && "is-5by4",
        this.is4by3 && "is-4by3",
        this.is3by2 && "is-3by2",
        this.is16by9 && "is-16by9",
        this.is2by1 && "is-2by1",
        this.is3by1 && "is-3by1",
        this.is4by5 && "is-4by5",
        this.is3by4 && "is-3by4",
        this.is2by3 && "is-2by3",
        this.is3by5 && "is-3by5",
        this.is9by16 && "is-9by16",
        this.is1by2 && "is-1by2",
        this.is1by3 && "is-1by3",
        this.isRounded && "is-rounded",
      ];

      return this.formatClassNames(classes);
    },
  },

  methods: {
    initImage(): void {
      this.isLoaded = true;
    },
  },
});
